import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { routes } from 'routes'
import { router } from '../../core/Root'
import * as emailActions from '../email/slice'
import * as folderActions from '../folder/slice'
import * as api from './api'

const customOnFulfilled = (folderId, thunkAPI, data) => {
  thunkAPI.dispatch(folderActions.get(folderId))
  thunkAPI.dispatch(getAll(folderId))
  thunkAPI.fulfillWithValue(data)
  thunkAPI.dispatch(getApproverInfo(folderId))
  const pathToNavigate = routes.folderDetails.path.replace(':id', folderId)
  router.navigate(pathToNavigate)
}

export const getAll = createAsyncThunk('folderHistory/GET_ALL', async (id, thunkAPI) => {
  const { data, error } = await api.getAll(id)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    return data
  }
})

export const getApproverInfo = createAsyncThunk('folderHistory/GET_APPROVER_INFO', async (id, thunkAPI) => {
  const { data, error } = await api.getApproverInfo(id)
  if (error) {
    return thunkAPI.rejectWithValue(error)
  } else {
    return data
  }
})

export const approveForControl = createAsyncThunk('folderHistory/APPROVE_FOR_CONTROL', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.approveForControl({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const approveForControlAndAssignTo = createAsyncThunk(
  'folderHistory/APPROVE_FOR_CONTROL_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.approveForControlAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const approveForSending = createAsyncThunk('folderHistory/APPROVE_FOR_SENDING', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.approveForSending({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const approveForSendingAndAssignTo = createAsyncThunk(
  'folderHistory/APPROVE_FOR_SENDING_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.approveForSendingAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const approveAndRequestAdditionalApproval = createAsyncThunk(
  'folderHistory/APPROVE_AND_REQUEST_ADDITIONAL_CONTROL',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.approveAndRequestAdditionalApproval({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const rejectControl = createAsyncThunk('folderHistory/REJECT_CONTROL', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.rejectControl({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const rejectControlAndAssignTo = createAsyncThunk(
  'folderHistory/REJECT_CONTROL_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.rejectControlAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const requestAudit = createAsyncThunk('folderHistory/REQUEST_AUDIT', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.requestAudit({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const requestAuditAndAssignTo = createAsyncThunk(
  'folderHistory/REQUEST_AUDIT_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.requestAuditAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const rejectSend = createAsyncThunk('folderHistory/REJECT_SEND', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.rejectSend({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const rejectSendAndAssignTo = createAsyncThunk(
  'folderHistory/REJECT_SEND_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.rejectSendAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const completeVatAudit = createAsyncThunk('folderHistory/COMPLETE_VAT_AUDIT', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.completeVatAudit({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const completeVatAuditAndAssignTo = createAsyncThunk(
  'folderHistory/COMPLETE_AUDIT_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.completeVatAuditAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const rejectVatAudit = createAsyncThunk('folderHistory/REJECT_VAT_AUDIT', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.rejectVatAudit({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const rejectVatAuditAndAssignTo = createAsyncThunk(
  'folderHistory/REJECT_VAT_AUDIT_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.rejectVatAuditAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const completeAudit = createAsyncThunk('folderHistory/COMPLETE_AUDIT', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.completeAudit({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const rejectAudit = createAsyncThunk('folderHistory/REJECT_AUDIT', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.rejectAudit({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const rejectAuditAndAssignTo = createAsyncThunk(
  'folderHistory/REJECT_AUDIT_AND_ASSING_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.rejectAuditAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const rejectAudited = createAsyncThunk('folderHistory/REJECT_AUDITED', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.rejectAudited({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const rejectAuditedAndAssignTo = createAsyncThunk(
  'folderHistory/REJECT_AUDITED_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.rejectAuditedAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const rejectSent = createAsyncThunk('folderHistory/REJECT_SENT', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.rejectSent({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const rejectSentAndAssignTo = createAsyncThunk(
  'folderHistory/REJECT_SENT_AND_ASSIGN_TO',
  async (values, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.rejectSentAndAssignTo({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    customOnFulfilled(values.folderId, thunkAPI, data)
  },
)

export const assignForApproval = createAsyncThunk('folderHistory/ASSIGN_FOR_APPROVAL', async (values, thunkAPI) => {
  const state = thunkAPI.getState()
  const { data, error } = await api.assignForApproval({
    ...values,
    actionToken: state.folder.actionToken,
  })
  if (error) return thunkAPI.rejectWithValue(error)
  customOnFulfilled(values.folderId, thunkAPI, data)
})

export const dispatchToFlow = createAsyncThunk(
  'folderHistory/DISPATCH_TO_FLOW',
  async ({ values, tableState }, thunkAPI) => {
    const state = thunkAPI.getState()
    const { data, error } = await api.dispatchToFlow({
      ...values,
      actionToken: state.folder.actionToken,
    })
    if (error) return thunkAPI.rejectWithValue(error)
    else {
      thunkAPI.fulfillWithValue(data)
      if (!tableState) {
        thunkAPI.dispatch(folderActions.get(values.folderId))
        thunkAPI.dispatch(getApproverInfo(values.folderId))
        const pathToNavigate = routes.folderDetails.path.replace(':id', values.folderId)
        router.navigate(pathToNavigate)
      } else {
        thunkAPI.dispatch(folderActions.getAll(tableState))
      }
    }
  },
)

export const clientReject = createAsyncThunk('folderHistory/CLIENT_REJECT', async (values, thunkAPI) => {
  const { error } = await api.clientReject(values)
  if (error) return thunkAPI.rejectWithValue(error)
  else {
    thunkAPI.dispatch(emailActions.getEmailInfo(values.emailGuid))
  }
})

export const slice = createSlice({
  name: 'folderHistory',
  initialState: {
    list: [],
    isListLoading: false,
    error: null,
    approvalsError: null,
    approverInfoModel: null,
    isApproverInfoModelLoading: false,
  },
  reducers: {
    clearError: state => {
      state.error = null
    },
    updateSelections: (state, { payload }) => {
      function updateSelected(rowId, isSelect) {
        return isSelect ? [...state.selections, rowId] : state.selections.filter(item => item !== rowId)
      }
      state.selections = updateSelected(payload.rowId, payload.isSelect)
    },
    cleareErrorsAndSelection: state => {
      state.EFacturaError = null
      state.selections = []
      state.cancelResult = null
    },
  },
  extraReducers: builder => [
    builder
      .addCase(getAll.pending, state => {
        state.isListLoading = true
        state.error = null
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isListLoading = false
        state.list = action.payload
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isModelLoading = false
        state.error = action.error
      })

      .addCase(getApproverInfo.pending, state => {
        state.isApproverInfoModelLoading = true
      })
      .addCase(getApproverInfo.fulfilled, (state, { payload }) => {
        state.approverInfoModel = payload
        state.isApproverInfoModelLoading = false
      })
      .addCase(getApproverInfo.rejected, (state, { error }) => {
        state.approvalsError = error
        state.isApproverInfoModelLoading = false
      })

      .addCase(approveForControl.pending, state => {
        state.error = null
        state.approvalsError = null
      })
      .addCase(approveForControl.fulfilled, () => {})
      .addCase(approveForControl.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(approveForControlAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(approveForControlAndAssignTo.fulfilled, () => {})
      .addCase(approveForControlAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(approveForSending.pending, state => {
        state.error = null
      })
      .addCase(approveForSending.fulfilled, () => {})
      .addCase(approveForSending.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(approveForSendingAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(approveForSendingAndAssignTo.fulfilled, () => {})
      .addCase(approveForSendingAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(approveAndRequestAdditionalApproval.pending, state => {
        state.error = null
      })
      .addCase(approveAndRequestAdditionalApproval.fulfilled, () => {})
      .addCase(approveAndRequestAdditionalApproval.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectControl.pending, state => {
        state.error = null
      })
      .addCase(rejectControl.fulfilled, () => {})
      .addCase(rejectControl.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectControlAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(rejectControlAndAssignTo.fulfilled, () => {})
      .addCase(rejectControlAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(requestAudit.pending, state => {
        state.error = null
      })
      .addCase(requestAudit.fulfilled, () => {})
      .addCase(requestAudit.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(requestAuditAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(requestAuditAndAssignTo.fulfilled, () => {})
      .addCase(requestAuditAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectSendAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(rejectSendAndAssignTo.fulfilled, () => {})
      .addCase(rejectSendAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(completeVatAudit.pending, state => {
        state.error = null
      })
      .addCase(completeVatAudit.fulfilled, () => {})
      .addCase(completeVatAudit.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(completeVatAuditAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(completeVatAuditAndAssignTo.fulfilled, () => {})
      .addCase(completeVatAuditAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectVatAudit.pending, state => {
        state.error = null
      })
      .addCase(rejectVatAudit.fulfilled, () => {})
      .addCase(rejectVatAudit.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectVatAuditAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(rejectVatAuditAndAssignTo.fulfilled, () => {})
      .addCase(rejectVatAuditAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(completeAudit.pending, state => {
        state.error = null
      })
      .addCase(completeAudit.fulfilled, () => {})
      .addCase(completeAudit.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectAudit.pending, state => {
        state.error = null
      })
      .addCase(rejectAudit.fulfilled, () => {})
      .addCase(rejectAudit.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectAuditAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(rejectAuditAndAssignTo.fulfilled, () => {})
      .addCase(rejectAuditAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectAudited.pending, state => {
        state.error = null
      })
      .addCase(rejectAudited.fulfilled, () => {})
      .addCase(rejectAudited.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectAuditedAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(rejectAuditedAndAssignTo.fulfilled, () => {})
      .addCase(rejectAuditedAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectSent.pending, state => {
        state.error = null
      })
      .addCase(rejectSent.fulfilled, () => {})
      .addCase(rejectSent.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectSentAndAssignTo.pending, state => {
        state.error = null
      })
      .addCase(rejectSentAndAssignTo.fulfilled, () => {})
      .addCase(rejectSentAndAssignTo.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(rejectSend.pending, state => {
        state.error = null
      })
      .addCase(rejectSend.fulfilled, () => {})
      .addCase(rejectSend.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(dispatchToFlow.pending, state => {
        state.error = null
      })
      .addCase(dispatchToFlow.fulfilled, () => {})
      .addCase(dispatchToFlow.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(assignForApproval.pending, state => {
        state.error = null
      })
      .addCase(assignForApproval.fulfilled, () => {})
      .addCase(assignForApproval.rejected, (state, { error }) => {
        state.error = error
      })

      .addCase(clientReject.pending, state => {
        state.error = null
      })
      .addCase(clientReject.fulfilled, () => {})
      .addCase(clientReject.rejected, (state, { error }) => {
        state.error = error
      }),
  ],
})

export const { clearError, updateSelections, cleareErrorsAndSelection } = slice.actions

export const listSelector = state => state.folderHistory.list
export const isListLoadingSelector = state => state.folderHistory.isListLoading
export const errorSelector = state => state.folderHistory.error
export const approvalsErrorSelector = state => state.folderHistory.approvalsError
export const approverInfoModelSelector = state => state.folderHistory.approverInfoModel
export const isApproverInfoLoadingSelector = state => state.folderHistory.isApproverInfoModelLoading

export const reducer = slice.reducer
