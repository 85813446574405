import React from 'react'

export const folderStatus = {
  Undispatched: 0,
  Storno: 1,
  New: 100,
  ReadyForControl: 200,
  ReadyForSending: 300,
  ReadyForAudit: 400,
  Sent: 500,
  Rejected: 600,
  Audited: 700,
  AuditInProgress: 800,
}

export const leadDocumentCategoryEnum = [
  {
    id: 1,
    name: 'bill',
  },
  {
    id: 2,
    name: 'certificate',
  },
  {
    id: 3,
    name: 'notification',
  },
  {
    id: 4,
    name: 'warning',
  },
  {
    id: 5,
    name: 'invoice',
  },
  {
    id: 6,
    name: 'letter',
  },
  {
    id: 7,
    name: 'annex',
  },
  {
    id: 8,
    name: 'ios',
  },
  {
    id: 9,
    name: 'creditInvoice',
  },
  {
    id: 10,
    name: 'debitNote',
  },
  {
    id: 11,
    name: 'proformaInvoice',
  },
  {
    id: 12,
    name: 'prepaymentInvoice',
  },
  {
    id: 13,
    name: 'offer',
  },
  {
    id: 100,
    name: 'other',
  },
]

export const integratorsEnum = [
  {
    id: 1,
    name: 'Asseco',
  },
]

export const channelDisabledReasons = {
  10: 'CompanyInfoNotComplete',
  20: 'CustomerInfoNotComplete',
  30: 'DisabledByCustomerSettings',
  40: 'CustomerNotEFakturaCompany',
  50: 'DocumentTypeNotSupported',
  60: 'FolderStatusMustBeReadyForSending',
  70: 'SistemEFakturaServiceUnavailable',
  80: 'IssuingDateMustBeTodaysDate',
}

export const EFakturaInvoiceStatus = {
  SendingToSefError: -100,
  SentToSef: -50,
  Draft: 100,
  Sent: 200,
  Mistake: 400,
  Sending: 700,
  //Deleted: 1000,
  Approved: 1100,
  Rejected: 1200,
  Cancelled: 1300,
  Storno: 1400,
}

export const CirInvoiceStatus = {
  None: 0,
  Active: 10,
  Invalid: 20,
  Cancelled: 30,
  PartiallySettled: 40,
  Settled: 50,
  Assigned: 60,
}

export const userStatus = {
  newUser: 'SuccessUserNotFoundCanCreateNew',
  newForCompany: 'SuccessUserExistsCanCreateAccount',
  exists: 'ErrorUserAccountAlreadyExists',
  existingApiUser: 'ErrorUserExistsAsAnApiUser',
}

export const imageExtensions = {
  svg: 'image/svg+xml',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
}

export const taxPointDate = {
  DeliveryDate: 1,
  IssuingDate: 2,
  PaymentDate: 3,
}

export const stageTypeEnum = {
  Referent: 1,
  Control: 2,
  Send: 3,
  AuditVat: 4,
  AuditReceivable: 5,
  Audited: 6,
}

export const stageNameEnum = {
  Referent: 'Referent',
  Control: 'Control',
  Send: 'Send',
  AuditVat: 'AuditVat',
  AuditReceivable: 'AuditReceivable',
  Audited: 'Audited',
}

export const channelTypeEnum = {
  Email: 1,
  Sef: 2,
}

export const exportTypeEnum = {
  XLSX: 1,
  CSV: 2,
}

export const activitiesTabelDataProps = {
  documentName: 'name',
  customerName: 'customerName',
  invoiceCategory: 'invoiceCategory',
  documentsCount: 'documentsCount',
  createdBy: 'invoiceCreatedBy',
  issuingDate: 'issuingDate',
  lastChangedByUser: 'lastChangedByUser',
  sefStatus: 'sefStatus',
  cirStatus: 'crfStatus',
  emailOpened: 'isOpened',
}

export const tabs = {
  new: 1,
  readyForControl: 2,
  ReadyForSending: 3,
  sent: 4,
  rejected: 5,
}

export const actionsEnum = {
  chooseCustomer: 1,
  addDocument: 2,
  downloadSefDocuments: 3,
  approveForControl: 4,
  rejectTbd: 5,
  approveForControlAndAssignTo: 6,
  massStorno: 7,
  massReject: 8,
  approveForSending: 9,
  approveForSendingAndAssignTo: 10,
  approveAndRequestAdditionalApproval: 11,
  rejectControl: 12,
  rejectControlAndAssignTo: 13,
  requestAudit: 14,
  requestAuditAndAssignTo: 15,
  rejectSend: 16,
  rejectSendAndAssignTo: 17,
  sendViaSef: 18,
  sendViaEail: 19,
  compose: 20,
  completeVATAudit: 21,
  completeVATAuditAndAssignTo: 22,
  completeAudit: 23,
  sendToFlow: 24,
  assign: 25,
  cancel: 26,
  storno: 27,
}

export const collapseStateEnum = {
  closed: 1,
  opened: 2,
  expanded: 3,
}

export const linkedCollapseStates = {
  1: { next: collapseStateEnum.opened, label: 'open' },
  2: { next: collapseStateEnum.expanded, label: 'expand' },
  3: { next: collapseStateEnum.closed, label: 'close' },
}

export const MassJobEnum = {
  SefStorno: 1,
  SefCancel: 2,
  SendToSef: 3,
  SendToEmail: 4,
  UpdateSefStatus: 5,
  ApproveInFlow: 6,
  RejectInFlow: 7,
  ApproveAndCompleteStatus: 8,
  ApproveAndRequestAdditionalApproval: 9,
  RejectAndAssignTo: 10,
  AssignForApproval: 11,
  DispatchToFlow: 12,
  SetFolderCustomer: 13,
  AddFolderAttachment: 14,
  CompleteAudit: 15,
}

export const massJobEnum = {
  // Sef
  sefStorno: 1,
  sefCancel: 2,
  sendToSef: 3,
  updateSefStatus: 4,

  // Email
  sendToEmail: 5,

  // Control
  approveForControl: 6,
  approveForControlAndAssignTo: 7,
  approveAndRequestAdditionalControl: 8,
  rejectControl: 9,
  rejectControlAndAssignTo: 10,

  // Sending
  approveForSending: 11,
  approveForSendingAndAssignTo: 12,
  rejectSending: 13,
  rejectSendingAndAssignTo: 14,
  rejectSent: 15,
  rejectSentAndAssignTo: 16,

  // Vat Audit
  requestAudit: 17,
  requestAuditAndAssignTo: 18,
  completeVatAudit: 19,
  completeVatAuditAndAssignTo: 20,
  rejectVatAudit: 21,
  rejectVatAuditAndAssignTo: 22,

  // Audit
  completeAudit: 23,
  rejectAudit: 24,
  rejectAuditAndAssignTo: 25,
  rejectAudited: 26,
  rejectAuditedAndAssignTo: 27,

  // Misc
  assignForApproval: 28,
  dispatchToFlow: 29,
  setFolderCustomer: 30,
  addFolderAttachment: 31,
}

export const FormsEnum = {
  ImplicitApproveForm: 'implicitApproveForm',
  ExplicitApproveForm: 'explicitApproveForm',
  ImplicitRejectForm: 'implicitRejectForm',
  ExplicitRejectForm: 'explicitRejectForm',
  CompleteVATAuditForm: 'completeVATAuditForm',
  AssignToFlowForm: 'assignToFlowForm',
  AssignToUserOrToFlowForm: 'assignToUserOrToFlowForm',
}

export const ActionIconPairs = {
  completeVATAudit: <i className="bi bi-file-check"></i>,
  approve: <i className="bi bi-file-check"></i>,
  chooseCustomer: <i className="bi bi-person"></i>,
  addDocument: <i className="bi bi-file-plus"></i>,
  approveForControlAndAssignTo: <i className="bi bi-file-earmark-check"></i>,
  approveForSending: <i className="bi bi-file-check"></i>,
  approveForSendingAndAssignTo: <i className="bi bi-file-earmark-check-fill"></i>,
  ApproveAndRequestAdditionalApproval: <i className="bi bi-file-earmark-check"></i>,
  rejectControl: <i className="bi bi-file-excel"></i>,
  rejectControlAndAssignTo: <i className="bi bi-file-earmark-x"></i>,
  requestAudit: <i className="bi bi-card-checklist"></i>,
  requestAuditAndAssignTo: <i className="bi bi-calendar-check"></i>,
  rejectSend: <i className="bi bi-file-excel"></i>,
  rejectSendAndAssignTo: <i className="bi bi-file-earmark-x"></i>,
  sendViaSef: <i className="bi bi-send"></i>,
  sendViaEail: <i className="bi bi-envelope-open"></i>,
  completeVATAuditAndAssignTo: <i className="bi bi-file-earmark-check"></i>,
  completeAudit: <i className="bi bi-card-checklist"></i>,
  assign: <i className="bi bi-file-earmark-person"></i>,
  assignToFlow: <i className="bi bi-box-arrow-in-right"></i>,
}

export const channelState = {
  Enabled: 10,
  disabled: 20,
  Sent: 30,
}

export const FolderStatusAndModalNamePairs = {
  [folderStatus.Undispatched]: 'chooseFlow',
  [folderStatus.New]: 'assignToUserModalNew',
  [folderStatus.ReadyForControl]: 'assignToUserModalReadyForControl',
  [folderStatus.ReadyForSending]: 'assignToUserModalReadyForSending',
  [folderStatus.Sent]: 'assignToUserModalSent',
  [folderStatus.Rejected]: 'assignToUserModalRejected',
  [folderStatus.ReadyForAudit]: 'assignToUserModalCompleteAudit',
  [folderStatus.Audited]: 'assignToUserModalRejectAudit',
}

export const MassJobSource = {
  MyActivitiesUndispatched: 0,
  MyActivitiesNew: 1,
  MyActivitiesRfc: 2,
  MyActivitiesRfs: 3,
  MyActivitiesRejected: 4,
  Audit: 5,
  Register: 6,
  MyActivitiesSent: 7,
}

export const OrderedJobs = {
  setFolderCustomer: 2,
  addFolderAttachment: 1,
  downloadSefDocument: 20,

  // Control
  approveForControl: 4,
  approveForControlAndAssignTo: 5,
  approveAndRequestAdditionalControl: 8,
  rejectControl: 9,
  rejectControlAndAssignTo: 10,

  // Sending
  approveForSending: 6,
  approveForSendingAndAssignTo: 7,
  rejectSending: 11,
  rejectSendingAndAssignTo: 12,
  rejectSent: 13,
  rejectSentAndAssignTo: 14,

  // Sef
  sefStorno: 18,
  sefCancel: 19,
  sendToSef: 16,
  resendToSef: 17,
  // updateSefStatus: 4,

  // Email
  sendToEmail: 15,

  // Misc
  assignForApproval: 33,
  dispatchToFlow: 32,

  // Vat Audit
  requestAudit: 21,
  requestAuditAndAssignTo: 22,
  completeVatAudit: 23,
  completeVatAuditAndAssignTo: 24,
  rejectVatAudit: 25,
  rejectVatAuditAndAssignTo: 27,

  // Audit
  completeAudit: 26,
  rejectAudit: 28,
  rejectAuditAndAssignTo: 29,
  rejectAudited: 30,
  rejectAuditedAndAssignTo: 31,
}

export const OrderedJobsForUndispatched = {
  // Misc
  dispatchToFlow: 1,
  assignForApproval: 2,
  setFolderCustomer: 3,
}

export const actionColors = {
  orange: '#FFA500',
  blue: '#19B5FE',
  green: '#73a36a',
  red: '#DC3444',
  purple: '#A87CA0',
}

export const groupedActions = [
  [{ id: OrderedJobs.addFolderAttachment }, { id: OrderedJobs.setFolderCustomer }],
  [
    { id: OrderedJobs.approveForControl },
    { id: OrderedJobs.approveForControlAndAssignTo },
    { id: OrderedJobs.approveForSending },
    { id: OrderedJobs.approveForSendingAndAssignTo },
    { id: OrderedJobs.approveAndRequestAdditionalControl },
  ],
  [{ id: OrderedJobs.rejectControl }, { id: OrderedJobs.rejectControlAndAssignTo }],
  [
    { id: OrderedJobs.rejectSending },
    { id: OrderedJobs.rejectSendingAndAssignTo },
    { id: OrderedJobs.rejectSent },
    { id: OrderedJobs.rejectSentAndAssignTo },
  ],
  [{ id: OrderedJobs.sendToEmail }],
  [
    { id: OrderedJobs.sendToSef },
    { id: OrderedJobs.resendToSef },
    { id: OrderedJobs.sefStorno },
    { id: OrderedJobs.sefCancel },
    { id: OrderedJobs.downloadSefDocument },
  ],
  [{ id: OrderedJobs.requestAudit }, { id: OrderedJobs.requestAuditAndAssignTo }],
  [
    { id: OrderedJobs.completeVatAudit },
    { id: OrderedJobs.completeVatAuditAndAssignTo },
    { id: OrderedJobs.rejectVatAudit },
  ],
  [{ id: OrderedJobs.completeAudit }, { id: OrderedJobs.rejectVatAuditAndAssignTo }],
  [{ id: OrderedJobs.rejectAudit }, { id: OrderedJobs.rejectAuditAndAssignTo }],
  [({ id: OrderedJobs.rejectAudited }, { id: OrderedJobs.rejectAuditedAndAssignTo })],
  [{ id: OrderedJobs.dispatchToFlow }, { id: OrderedJobs.assignForApproval }],
]


export const FeatureFlags = {
 Sef: 1
}